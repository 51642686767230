.page-wrapper--order-confirmation {
    .page-content {
        border: none;
    }
}
.page-wrapper--history {
    border: none;
    .page-header {
        border: none;
        h1 {
            font-weight: 900;
            text-align: center;
            letter-spacing: .03em;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }
    }
    .page-content {
        h6 {
            font-size: $custom-small-font-size-base;
            color: #0e4d76;
            font-weight: 300;

            @include media-breakpoint-up(lg){
              font-size: $custom-medium-font-size-base;
            }
            @include media-breakpoint-up(xxl){
              font-size: $custom-large-font-size-base;
            }
        }
        table {
            th,
            td {
                vertical-align: middle;
                text-align: center;
            }
            th {
                text-align: center;
            }
            .btn {
                @include media-breakpoint-up(lg) {
                    padding: 10px 20px;
                }
            }
        }
        .table-striped tbody tr:nth-of-type(odd) {
            background-color: #FFF;
        }
        .table-striped thead tr {
            background-color: $tertiary;
            th {
                border-color: #fafafa;
            }
        }
    }
    .page-footer {
        border-top: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        a{
            &:last-child {
                margin-top: 10px;
            }
        }
        @include media-breakpoint-up(xl) {
            background-color: transparent;
        }
    }
}
.page-order-slip {
    h6 {
        color: #0e4d76;
        font-weight: 300;
        margin-bottom: 20px;
        font-size: $custom-small-font-size-base;

        @include media-breakpoint-up(lg){
          font-size: $custom-medium-font-size-base;
        }
        @include media-breakpoint-up(xxl){
          font-size: $custom-large-font-size-base;
        }
    }
}