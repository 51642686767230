.paypal-buttons{
  #cart .cart-detailed-actions &{
    display: none !important;
  }
}
#cart .cart-detailed-actions [data-container-bnpl]{
  margin: 0 !important;
}

#wkSubscriptionModal {
  padding-right: 0;
  #wkModelLabel {
    width: 100%;
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
  }
  .modal-header .close {
    padding-left: 0;
    margin-left: 0;
  }
  .modal-footer .btn:not(:last-child) {
    margin-top: 0;
  }
  .modal-footer .btn {
    @include media-breakpoint-up(xxl) {
      padding: 15px 45px;
    }
  }
  form .form-group {
    margin-bottom: 0;
    > div:first-child {
      margin-bottom: 20px;
    }
    label {
      font-size: 15px;
      font-weight: 500;
    }
    .form-control {
      color: $primary;
      border: 1px solid $primary;
      border-radius: 100px;
      font-weight: 400;
      font-size: 14px;
    }
  }
}