#our-favorites {
    padding-bottom: 1.5rem;
    .chose-categeroy-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
            margin-bottom: 10px;
            width: fit-content;
            padding: 15px 10px;
            &:first-child {
                margin-right: 15px;
            }
        }
        @include media-breakpoint-up(md) {
            .btn:first-child {
                margin-right: 30px;
            }
        }
    }
    .featured-products {
        &.our-new-products {
            display: none;
        }
    }
    @include media-breakpoint-down(md){
      .product-miniature{
        &:nth-child(2n+1){
          padding-right: 7.5px;
        }
        &:nth-child(2n){
          padding-left: 7.5px;
        }
      }
    }
    @include media-breakpoint-up(lg){
      padding-bottom: 3rem;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 4.25rem!important;
        margin-bottom: 5.5rem;
    }
    @include media-breakpoint-up(xxl) {
        padding-top: 0!important;
    }
}