.products {
  .product-miniature {
    img {
      width: 100%;
      &.out-of-stock {
        filter: brightness(75%);
      }
    }
    .out-of-stock-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: calc(50% - 25px);
      left: 0;
      width: 100%;
      min-height: 50px;
      font-weight: 700;
      font-size: 15px;
      text-transform: uppercase;
      line-height: 18px;
      color: #FFF;
    }
    .product-description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-inline: 15px;
      padding-top: 10px;
      .product-title {
        @include custom-font-size();
        font-weight: 700;
        line-height: 14px;
        margin-bottom: 5px;
        text-align: center;
        min-height: 32px;
        @include media-breakpoint-up(sm) {
          min-height: 30px;
        }
        @include media-breakpoint-up(md) {
          line-height: 20px;
          min-height: 40px;
        }
        @include media-breakpoint-up(lg) {
          line-height: 22px;
          margin-bottom: 10px;
          min-height: 65px;
        }
        @include media-breakpoint-up(xl) {
          min-height: 50px;
        }
        @include media-breakpoint-up(xxl) {
          line-height: 35px;
          min-height: 75px;
        }
      }
      .product-price-and-shipping {
        .price {
          font-weight: 900;
          font-size: 1rem;
          @include media-breakpoint-up(lg) {
            font-size: (20 / 16) + rem;
          }
          @include media-breakpoint-up(xxl) {
            font-size: (33 / 16) + rem;
          }
        }
      }
      .regular-price{
        text-decoration: line-through;
        font-weight: 500;
        font-size: 1rem;
        margin-right: 5px;
        @include media-breakpoint-up(xxl) {
          font-size: (25 / 16) + rem;
          margin-right: 20px;
        }
      }
    }
  }
}