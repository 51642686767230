.l-footer {
  color: $text-muted;


  .cms-page-link,
  .custom-page-link,
  .category-link,
  .account-list,
  .bottom-footer-link,
  .account-list a {
    color: white;
    font-weight: 500;
    font-size: 1rem;
    line-height: 25px;
    text-align: center;
    &:hover{
      color: $secondary;
    }
    &#footer_store_info {
      @include media-breakpoint-down(lg) {
        max-width: 250px;
        margin-inline: auto;
      }

      &:hover {
        color: white;
      }
    }
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
    @include media-breakpoint-up(xxl) {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 40px;
    }
  }
  .account-list {
    p {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      img {
        width: 23px;
        max-width: 23px;
        @include media-breakpoint-up(xxl) {
          margin-top: 12px;
        }
      }
      span {
        text-align: left;
        padding-left: 10px;
        word-break: break-word;
      }
    }
  }

  .social-medias {
    .social-medias-buttons {
      text-align: center;
      position: relative;
      left: 7px;
      .social-media-btn {
        position: relative;
        z-index: 5;
        right: 14px;
        img {
          max-width: 60px;
          @include media-breakpoint-up(xxl) {
            max-width: 80px;
          }
        }
        &:first-child {
          right: 0;
          z-index: 2;
        }
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
  }

}
.footer-container{
  margin-top: $footer-container-margin-top;
  hr {
    border-top: 1px solid white;
    width: 100%;
  }
  li {
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }
  .bottom-footer {
    margin-inline: 0;
    .payments {
      display: flex;
      justify-content: center;
      position: relative;
      left: 7px;
      img {
        max-width: 60px;
        position: relative;
        z-index: 9;
        right: 8px;
        &:first-child {
          max-width: 68px;
          position: relative;
          z-index: 10;
          right: -8px;
        }
        &:nth-child(3n) {
          right: 16px;
          z-index: 8;
        }
        &:nth-child(4n) {
          right: 24px;
          z-index: 7;
        }
      }
      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
        padding-left: 0;
      }
      @include media-breakpoint-up(xxl) {
        img {
          max-width: 80px;
          &:first-child {
            max-width: 88px;
          }
        }
      }
    }
  }
}

.footer__title {
  @extend .h3;
  font-weight: 900;
  color: $secondary;
  text-align: center;
  margin-bottom: 15px;
  @include media-breakpoint-up(lg) {
    text-align: left;
    margin-bottom: 28px;
  }
  @include media-breakpoint-up(xl) {
    font-size: (30 / 16) + rem!important;
  }
  @include media-breakpoint-up(xxl) {
    font-weight: 900;
    font-size: (37 / 16) + rem!important;
  }
}

.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}
