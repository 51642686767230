.blockcart-modal{
  color:$primary;

  .modal-header{
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.5);
    
    .modal-title{
      font-weight: 800;
      font-size: 1rem;
      svg{
        margin-bottom: 10px;
      }

      @include media-breakpoint-up(lg){
        padding: 10px 60px;
        font-size: (24 / 16) + rem;
        
        svg{
          margin-bottom: 0;
          margin-right: 34px;
        }
      }
      @include media-breakpoint-up(xxl){
        font-size: (30 / 16) + rem;
      }
    }
    button.close{
      position: absolute;
      top: 9px;
      right: 9px;
      opacity: 1;
      
      @include media-breakpoint-up(lg){
        font-size: 2rem;
        right: 33px;
        top: 30px;
      }
    }
  }

  .modal-body{
    .product-name{
      font-weight: 900;
      font-size: 1rem;

      @include media-breakpoint-up(lg){
        font-size: (24 / 16) + rem;
      }
    }
    .regular-price{
      text-decoration: line-through;
    }
    .cart-content{
      &:not(.cart-products-count){
        color: $black;
        font-weight: 800;
      }
      .cart-products-count{
        font-weight: 800;
        font-size: 1rem;
        color: $gray;

        @include media-breakpoint-up(lg){
          font-size: (24 / 16) + rem;
        }
      }
    }
    @include media-breakpoint-down(md){
      padding-bottom: 0;
    }

    @include media-breakpoint-up(lg){
      padding: 38px 60px;
      .divider-right{
        border-right: 1px solid rgba($color: $black, $alpha: .3)
      }
    }
  }
  .modal-footer{
    border-top: none;
    button, a{
      width: auto;
      transition: all .2s;
    }
    button{
      background-color: rgba($color: $primary, $alpha: .7);
      border: none;
      @include media-breakpoint-down(md){
        margin-bottom: 18px;
      }
      &:hover{
        color: $white;
        background-color: $primary;
      }
    }
    a{
      svg{
        margin-right: 10px;
        &:hover{
          border: none;
        }
      }
      &:hover{
        path{
          fill: $primary;
        }
      }
    }
    @include media-breakpoint-down(md){
      flex-direction: column;
    }
  }
}