.l-header{
  background-color: $header-bg;
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  max-height: 40px;
  @include media-breakpoint-up(xsm) {
    height: 50px;
    max-height: 50px;
  }
  @include media-breakpoint-up(sm) {
    height: 55px;
    min-height: 55px;
  }
  @include media-breakpoint-up(lg){
    height: initial;
    min-height: initial;
    max-height: initial;
  }
}

.header__right,
.header-nav__right{
  display: flex;
  justify-content: flex-end;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-mobile{
  .header__logo,
  .header__logo--checkout{
    width: 50%;
    max-width: 50%;
    text-align: center;
  }
  .header__search {
    width: fit-content;
    padding-left: 10px;
    #search_widget {
      .search-btn {
        border: none;
        color: #fff;
        width: 70px;
        padding: 0;
        background: #0e4d76;
        height: 100%;
        right: 0;
        border-top-right-radius: 35.5px;
        border-bottom-right-radius: 35.5px;
        .search {
          height: 21px;
          @include media-breakpoint-up(xxl) {
            height: 26px;
          }
        }
      }
    }
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 25%;
  }
}
@include media-breakpoint-up(xxl) {
  .header-top__col{
    width: fit-content;
  }
}

#header {
  .header-top {
    .header__left {
      width: 25%;
      max-width: 25%;    
      #menu-icon {
        background: none;
        border: none;
        svg {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        order: 1;
        width: auto;
        max-width: 500px;
        justify-content: center;
      }
      @include media-breakpoint-up(xxl) {
        width: 40%;
        max-width: 40%;
        justify-content: center;
      }
    }
    .header__logo {
      img {
        width: 100%;
        @include media-breakpoint-up(sm) {
          max-height: 55px;
          width: auto;
        }
        @include media-breakpoint-only(lg){
          max-width: 200px;
        }
        @include media-breakpoint-only(xxl){
          max-height: 80px;
        }
      }
      max-width: 45%;
      @include media-breakpoint-up(xxl){
        width: 30%;
        max-width: 30%;
      }
    }
    .header__right {
      width: 25%;
      max-width: 25%;
      .blockcart {
        .shopping-cart {
          .img-and-count {
            width: fit-content;
            text-align: center;
            position: relative;
            @include media-breakpoint-down(md){
              right: 10px;
            }
            img {
              width: 100%;
              max-width: 34px;
              @include media-breakpoint-up(xl){
                width: 45px;
                max-width: 45px;
              }
              @include media-breakpoint-up(xxl){
                width: 60px;
                max-width: 60px;
              }
            }
            .cart-products-count {
              font-weight: 500;
              padding-inline: 7px;
              padding-bottom: 2px;
              border-radius: 100px;
              position: absolute;
              right: -10px;
              bottom: -8px;

              @include media-breakpoint-up(xl){
                bottom: 2px;
              }
              @include media-breakpoint-up(xxl){
                font-size: 1.25rem;
                padding-inline: 10px;
                right: -15px;
              }
            }
          }
        }
      }
      @include media-breakpoint-up(lg) {
        order: 2;
        justify-content: space-between;
        align-items: flex-start;
        width: auto;
        max-width: none;
        .menu__icon-text {
          img {
            width: 34px;
            max-width: 34px;

            @include media-breakpoint-up(xl){
              width: 45px;
              max-width: 45px;
            }
            @include media-breakpoint-up(xxl){
              width: 60px;
              max-width: 60px;
            }
          }
        }
      }
      @include media-breakpoint-up(xxl) {
        width: 30%;
        max-width: 30%;
        .header__rightitem {
          a, div {
            span.visible--desktop {
              font-weight: 400;
              font-size: 1.375rem;
              line-height: 30px;
              margin-top: 5px;
              text-align: center;
              color: $primary;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    height: auto;
    max-height: 100%;
    position: relative;
  }
}