#redirections-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include media-breakpoint-up(xl){
      height: 600px;
    }
    @include media-breakpoint-up(xxl){
      height: 800px;
      margin-top: 8.5rem!important;
      margin-bottom: 5rem;
    }
    .wrapper-2-3-4 {
        display: flex;
        flex-direction: column;
        width: 100%;
        .wrapper-3-4 {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        @include media-breakpoint-up(xl){
          height: 100%;
          width: 70%;
          max-width: 69%;
          .wrapper-3-4 {
              height: 50%;
              max-height: 49%;
              .redirection {
                  height: 100%;
              }
          }
          .redirection {
              &-2 {
                  height: 50%;
                  max-height: 49%;
              }
          }
        }
    }
    .redirection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 30px;
        padding: 10px;
        h3 p {
          font-weight: 900;
          @include media-breakpoint-down(lg){
            text-align: center !important;
          }
          @include media-breakpoint-up(xl){
            margin-bottom: 35px;
            font-size: (30 / 16) + rem;
          }
          @include media-breakpoint-up(xxl){
            font-size: 3rem;
          }
        }
        .btn {
            border: none;
            max-width: 100%;
            line-height: 16px;
            padding: 15px 60px;
            @include media-breakpoint-up(sm) {
                padding: 20px 88px;
            }
        }
        &-1, &-2 {
            height: 185px;
            max-height: 100%;
            width: 100%;
            @include media-breakpoint-up(sm) {
                height: 200px;
            }
            @include media-breakpoint-up(lg) {
                height: 260px;
            }
            @include media-breakpoint-up(xl){
              .btn {
                padding: 22px 60px;
                font-weight: 800!important;
              }
            }
        }
        &-3, &-4 {
            height: 270px;
            width: 50%;
            max-width: 48.5%;
            @include media-breakpoint-up(md) {
                max-width: 48%;
            }

            .btn {
                padding: 15px 24px;
                @include media-breakpoint-up(sm) {
                    padding: 20px 52px;
                }
                @include media-breakpoint-up(md) {
                    padding: 20px 45px;
                }
            }
        }
    }

    @include media-breakpoint-up(xl){
        .redirection {
            &-1 {
                height: 100%;
                width: 30%;
                max-width: 29%;
                justify-content: flex-start;
                padding-top: 60px;
            }
            &-2{
                align-items: flex-start;
                padding-left: 60px;
                @include media-breakpoint-up(xxl) {
                    max-height: 49%;
                }
                h3 {
                    width: 50%;
                    max-width: 50%;
                    text-align: left;
                    word-break: break-word;
                }
            }
        }
    }
}