/* Globals menu variables */
/**************************/
$colorsMenu: $red, $secondary, $primary, $green, $purple, #757575, $secondary;
$mobile-xs-menu-height: 82px;
$mobile-xsm-menu-height: 98px;
$mobile-menu-height: 103px;
$desktop-menu-height: 65px;
/* mobile font */
$mobile-menu-font-lvl-1: (20 / 16) + rem;
$mobile-menu-font-lvl-2: (18 / 16) + rem;
$mobile-menu-font-lvl-3: (18 / 16) + rem;
/* tablet font */
$tablet-menu-font-lvl-1: (20 / 16) + rem;
$tablet-menu-font-lvl-2: (20 / 16) + rem;
$tablet-menu-font-lvl-3: (18 / 16) + rem;
// /* desktop font */
$desktop-xxl-menu-font-lvl-1: (25 / 16) + rem;
$desktop-xxl-menu-font-lvl-2: (25 / 16) + rem;
$desktop-xxl-menu-font-lvl-3: (23 / 16) + rem;
/**************************/

#desktop_top_menu_wrapper, #_mobile_top_menu, .js-top-menu-bottom{
  *{
    font-family: 'Poppins';
  }
  li{
    cursor: pointer;
    & a.current{
      color: $secondary;
    }
    &.current{
      color: $secondary;
    }
  }

  ul:not([data-depth="0"]) li{
    @include media-breakpoint-up(lg){
      &:hover a{
        color: $secondary;
      }
      &:hover path{
        stroke: $secondary;
      }
      svg{
        transform: rotate(-90deg);
        margin-left: 15px;
      }
    }
    &:not(:last-child){     
      @include media-breakpoint-up(lg){
        margin-bottom: $spacer;
      }
    }
  }
  /* menu lvl 1 */
  ul[data-depth="0"] li, .u-link-body{
    font-weight: 800;
    text-transform: uppercase;
  }
  ul[data-depth="1"] li{
    font-weight: 700;
  }
  ul[data-depth="2"] li{
    font-weight: 600;
    text-transform: initial;
  }
  .icon-collapse, .menu-bottom-link svg{
    margin-left: 20px;
    @include media-breakpoint-up(lg){
      margin-left: 10px;
    }
  }
}

/* mobile */
@include media-breakpoint-mobile() {
  #mobile_top_menu_wrapper{
    height: calc(100% - #{$mobile-xs-menu-height});
    top: auto;
    bottom: 0;
    @include media-breakpoint-only(xsm){
      height: calc(100% - #{$mobile-xsm-menu-height});
    }
    @include media-breakpoint-up(sm){
      height: calc(100% - #{$mobile-menu-height});
    }
    .modal-dialog{
      width: 100%;
      max-width: 100%;  
      .modal-content{
        box-shadow: none;
        height: 100%;
      }
      .modal-body{
        padding: 30px;
      }
    }
    ul[data-depth="0"] li, .menu-bottom-link{
      font-size: $mobile-menu-font-lvl-1;
      &:not(:last-child){
        margin-bottom: $spacer;
      }
    }
    ul[data-depth="1"] li{
      margin-left: 30px;
      margin-right: 0;
      font-size: $mobile-menu-font-lvl-2;
      &:first-child{
        margin-top: 10px;
      }
    }
    ul[data-depth="2"] li{
      margin-left: 30px;
      font-size: $mobile-menu-font-lvl-3;
    }
    .menu__item {
      &--icon.current path{
        stroke: $secondary;
      }
    }
    .js-top-menu-bottom{
      border-top: 2px solid $gray-400;
      padding-top: $spacer*1.5;
      margin-top: $spacer*1.5;
      .u-link-body{
        flex-direction: row;
        justify-content: start;

        &:hover{
          color: $secondary;
        }
        &:hover path{
          stroke: $secondary;
        }

        svg{
          transform: rotate(-90deg);
        }
      }
    }
  }
}

/* desktop */
@include media-breakpoint-desktop {
  #desktop_top_menu_wrapper{
    @for $i from 1 to length($colorsMenu) {
      .menu__item:nth-of-type(#{$i}) {
        border-top: 8px solid transparent;
        transition: all .2s;
        color: $primary;
        &:hover {
          border-top: 8px solid nth($colorsMenu, $i);
        }
      }
    }
    .menu-top{
      position: relative;
      height: $desktop-menu-height;
    }
    .menu-sub{
      position: absolute;
      width: 100%;
      left: 0;
      top: $desktop-menu-height;
      z-index: 10;
      background-color: $white;
      box-shadow: 0px -10px 50px rgba($color: $black, $alpha: 0.1);
      
      &--wrapper{
        padding: 20px 0 30px 40px;
  
        @include media-breakpoint-up(xxl) {
          padding: 38px 0 38px 88px;
        }
      }
      &--image{
        margin-top: -20px;
        @include media-breakpoint-up(xxl) {
          margin-top: -38px;
        }
      }
    }
    ul[data-depth="0"] li{
      font-size: 0.8rem;
      margin-inline: 20px;
      @include media-breakpoint-up(xl){
        font-size: 1rem;
      }
      @include media-breakpoint-up(xxl){
        margin-inline: 45px;
        font-size: $desktop-xxl-menu-font-lvl-1;
      }

      ul[data-depth="1"] li{
        font-size: $tablet-menu-font-lvl-2;
        @include media-breakpoint-up(xxl){
          font-size: $desktop-xxl-menu-font-lvl-2;
        }
      }
      ul[data-depth="2"] li{
        font-size: $tablet-menu-font-lvl-3;
        @include media-breakpoint-up(xxl){
          font-size: $desktop-xxl-menu-font-lvl-3;
        }
      }
    }
    a.current[data-depth="0"]{
      color: $primary;
    }
  }
}