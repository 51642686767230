.page-category {
    @include media-breakpoint-down(xxl) {
        p {
            font-size: 1rem!important;
        }
    }
    @include media-breakpoint-up(lg) {
        #category-description {
            h2 {
                font-size: 2rem!important;
            }
            h3 {
                font-size: 1.5rem!important;
            }
        }
    }
    @include media-breakpoint-up(xxl) {
        p {
            font-size: 1.25rem!important;
        }
    }
    #category-presentation {
        .category-header {
            h1 {
                font-weight: 900;
                font-size: 1.875rem;
                line-height: 2.25rem;
                text-align: center;
                letter-spacing: 0.03em;
                @include media-breakpoint-up(lg) {
                    font-size: 3.4375rem;
                    line-height: 4.6875rem;
                }
            }    
            .subcategories-wrapper {
                justify-content: center;
                #product-list-subcategory {
                    justify-content: center;
                    .product-list-subcategory-item {
                        .btn {
                            font-weight: 900!important;
                            padding: 10px 15px;
                            margin-inline: 5px;
                            margin-bottom: 10px;
                            @include media-breakpoint-up(xxl) {
                                padding-inline: 35px;
                                padding-top: 15px;
                                padding-bottom: 15px;
                            }
                        }
                        @include media-breakpoint-up(lg){
                            height: fit-content;
                        }
                    }
                }
                @include media-breakpoint-up(lg) {
                    &.has-guide {
                        display: flex;
                        #product-list-subcategory {
                            width: 65%;
                            max-width: 65%;
                            height: fit-content;
                            align-items: flex-start;
                            justify-content: flex-start;
                        }
                        .category-guide-block {
                            width: 35%;
                            max-width: 35%;
                        }
                    }
                }
            }
        }
    }
    .category-guide-block {
        .shopping-guide {
            .btn {
                width: fit-content;
                border-radius: 0;
                &:hover {
                    background-color: $primary;
                    color: white;
                    border: 2px solid $primary;
                }
                &:focus {
                    box-shadow: none!important;
                }
            }
            img {
                height: 14px;
                position: relative;
                top: 2px;
                margin-left: 5px;
            }
            &--desktop {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 200px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                p {
                    font-size: 2rem;
                    font-weight: 600;
                    color: white;
                }
                span {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    border: none;

                    &:hover{
                      border: none;
                    }
                }
                @include media-breakpoint-up(xl) {
                    height: 250px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 415px;
                }
            }
        }
        @include media-breakpoint-down(md) {
            background-color: white!important;
            margin-inline: -30px;
        }
        @include media-breakpoint-down(xs) {
            margin-inline: -15px;
        }
        @include media-breakpoint-up(sm) {
            background-color: transparent!important;
            margin-inline: 0;
        }
    }
    #products {
        .products-selection {
            .filter-buttons {
                .btn {
                    line-height: 1.5rem;
                }
                select {
                    background: transparent;
                    background-image: url(../img/design/dropdown.svg);
                    background-repeat: no-repeat;
                    background-position-x: 95%;
                    background-position-y: 55%;
                    height: 100%;
                    &:hover,
                    &:focus {
                        color: $primary;
                        option {
                            color: $primary;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        background-image: url(../img/design/down-arrow.svg);
                        background-size: 17px;
                        background-position-y: 51%;
                    }
                }
            }
        }
        #js-product-list {
            .u-a-i-c {
                font-size: 0.8125rem;
                line-height: 18px;
                .affichage {
                    font-size: 1rem;
                    @include media-breakpoint-up(xl) {
                        font-size: 1.2rem;
                        line-height: 1.4rem;
                    }
                }
                nav {
                    .pagination {
                        .page-item {
                            .page-link {
                                border: none;
                                color: $primary;
                                background: none;
                                &.previous,
                                &.next {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: $primary;
                                    border-radius: 50%;
                                    height: 55px;
                                    width: 55px;
                                }
                                @include media-breakpoint-up(md){
                                  font-size: $custom-medium-font-size-base;
                                }
                                @include media-breakpoint-up(xxl){
                                  font-size: $custom-large-font-size-base
                                }
                            }
                            &.active {
                                .page-link {
                                    color: $secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .category-description {
        text-align: center;
        color: $primary;
        font-weight: 300;
        .morelink {
            p {
                text-decoration: underline;
            }
        }
        @include media-breakpoint-up(lg) {
            hr.separation {
                border-bottom: 2px solid $tertiary;
                margin-inline: 15px;
                max-width: 960px;
            }
        }
        @include media-breakpoint-up(xl) {
            max-width: 55%;
            hr.separation {
                max-width: 1140px;
            }
        }
        @include media-breakpoint-up(xxl) {
            hr.separation {
                max-width: 1744px;
            }
        }
    }
}