@media print { 
  #header,
  #footer,
  .blockreassurance_product,
  .product-thumbs,
  .product-accessories,
  .product-opinion-print,
  .covered_social_security .circle,
  button.add-to-cart,
  .product-additional-info,
  .attachment-title, .attachment,
  .product-flags,
  .contact-banner-wrapper, 
  #product .read-more { 
    display: none !important; 
  }
  img{
    max-width: 70% !important;
  }
  .l-wrapper--boxed{
    padding: 0!important;
  }
  .content-product-topRight{
    margin: 0!important;
    padding: 0!important;
    
    .product-price{
      .current-price{
        text-align: start!important;
        display: flex;
  
        .current-price-display{
          margin-right: 20px;
        }
      }
    }
    .product-variants--wrapper{
      .label-color--wrapper{
        display: none;
      }
      .size{
        display: inline-block;
        select{
          border: unset!important;
          padding: 0px;
          height: unset;
          vertical-align: baseline;
        }
      }
    }
    .product-quantity--wrapper{
      flex-direction: row!important;
      input{
        border: unset!important;
        padding: 0px 8px!important;
        height: unset!important;
        text-align: start!important;
      }
      .dw-input-number--nav{
        display: none;
      }
    }
  }
  .product-main-desc{
    padding: 0!important;
  }

  /* destroy slick */
  .slick-track {
    flex-direction: column!important;
  }
  .slick-list {
    overflow: visible!important;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    width: auto!important;
    height: auto!important;
    transform: none!important;
    overflow: visible!important;
  }
  .slick-track .slick-slide {
    display: block!important;
    overflow: visible!important;
  }
  .slick-arrow,
  .slick-dots,
  .slick-track .slick-cloned {
    display: none!important;
  }
  .product-img.slick-slide:not(.product-img.slick-current){
    display: none!important;
  }
}