.search-widget{
  margin: 0 auto;
  position: relative;
  input.form-control {
    border: 3px solid $primary;
    border-radius: 35.5px;
    height: 50px;
    padding: 6px 75px 8px 20px;
    &::placeholder {
      color: $gray;
    }
    @include media-breakpoint-up(xxl) {
      height: 70px;
      font-weight: 700;
      font-size: (24 / 16) + rem;
    }
  }
  .search-btn {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    color: #fff;
    width: 70px;
    padding: 0;
    background: #0e4d76;
    height: 100%;
    border-top-right-radius: 35.5px;
    border-bottom-right-radius: 35.5px;
    .search {
      height: 21px;
      path {
        fill: $white;
      }
      @include media-breakpoint-up(lg) {
        height: 26px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    max-width: unset;
    >form {
      max-width: 300px;
      width: 300px;
    }
  }
  @include media-breakpoint-up(xl) {
    >form {
      max-width: 350px;
      width: 350px;
    }
  }
  @include media-breakpoint-up(xxl){
    width: 100%;
    max-width: 100%;
    >form {
      max-width: 550px;
      width: 550px;
      margin-left: 26px;
    }
  }
}
.search-widget__group{
  position: relative;
}
.search-widget__input-right{
  padding-right: 50px ;
}
.search-widget__btn{
  position: absolute;
  right: 0;
  top: 0;
}
@include media-breakpoint-mobile{
  .search-widget{
    max-width: unset;
  }
}

#mobile-search{
  #search_widget{
    position: relative;
  }
  .search-btn{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-55%);
  }
  #mobile-search--close{
    i{
      opacity: 1;
      font-size: 2rem;
      font-weight: 700;
    }
  }
}