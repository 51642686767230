.swiper {
    .swiper-wrapper {
        .swiper-slide {
            .card {
                min-height: 100%;
                height: 180px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                .card-body {
                    padding: 0.8rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .card-title {
                        font-size: (20 / 16) + rem;
                        font-weight: 900;
                        margin-bottom: 15px;
                        letter-spacing: 1.1px;
                        @include media-breakpoint-up(md) {
                            font-size: (30 / 16) + rem;
                            margin-bottom: 15px;
                        }
                        @include media-breakpoint-up(lg) {
                            font-size: 1.5rem;
                            margin-bottom: 20px;
                        }
                        @include media-breakpoint-up(xl) {
                            font-size: (30 / 16) + rem;
                        }
                        @include media-breakpoint-up(xxl) {
                            font-size: (40 / 16) + rem;
                        }
                    }
                    .btn {
                        padding: 12px 28px;
                        @include media-breakpoint-up(md) {
                            padding: 18px 40px;
                        }
                    }
                    @include media-breakpoint-up(md) {
                        padding: 1rem;
                    }
                    @include media-breakpoint-up(xl) {
                        padding: 1.2rem;
                    }
                }
                @include media-breakpoint-up(sm) {
                    height: 220px;
                }
                @include media-breakpoint-up(md) {
                    height: 300px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 450px;
                }
            }
        }
    }
}