.page-wrapper--authentication,
.page-wrapper--password,
.page-wrapper--identity,
.page-wrapper--address,
.page-wrapper--addresses,
.page-wrapper--module-psgdpr-gdpr,
.page-wrapper--contact,
.page-contact,
.page-wrapper--discount,
.page-wrapper--order-slip,
.page-wrapper--order-detail {
    border: none;
    h1,
    .contact-rich h4 {
        color: $primary;
        font-weight: 900;
        text-align: center;
        letter-spacing: .03em;
    }
    .page-header,
    .contact-rich .infos-header {
        border: none;
        background-color: $tertiary;
        padding: .75rem 1.25rem;
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }
        @include media-breakpoint-up(xl) {
            background-color: white;
        }
    }
    h3 {
        @include media-breakpoint-up(md) {
            font-size: 1.6rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 2rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 2.3rem;
            margin-bottom: 30px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 2.8rem;
        }
    }
    .page-content {
        .login-form,
        .register-form {
            >p {
                color: $primary;
                text-align: center;
                a {
                    text-decoration: underline;
                }
            }
        }
        form {
            .form-group {
                label,
                input.form-control,
                select.custom-select {
                    color: $primary;
                    font-weight: 600;
                }
                input.form-control, 
                select.custom-select {
                    font-weight: 400;
                }
                .input-group {
                    align-items: center;
                }
                .input-group-btn {
                    .btn {
                        background-repeat: no-repeat;
                        background-position: center;
                        height: 100%;
                        max-width: 100%;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        &.hide {
                            background-image: url(../img/design/hide.png);
                        }
                        &.show {
                            background-image: url(../img/design/view.png);
                        }
                        &:hover {
                            background-color: white;
                        }
                    }
                }
                .custom-control {
                    label {
                        font-weight: 400;
                        @include media-breakpoint-up(lg) {
                            &::before,
                            &::after {
                                top: 0.3rem;
                            }
                        }
                    }
                }
                .forgot-password {
                    text-align: center;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    a {
                        text-decoration: underline;
                    }
                }
                @include media-breakpoint-up(xxl) {
                    margin-bottom: 2rem;
                }
            }
            .form-footer {
                text-align: center;
                margin-top: 0;
                margin-bottom: 30px;
                @include media-breakpoint-up(lg) {
                    margin-top: 30px;
                }
            }
            @include media-breakpoint-up(lg) {
                width: 100%;
                max-width: 100%;
            }
        }
        .forgotten-password {
            width: 100%;
            max-width: 100%;
            .form-fields {
                text-align: center;
                .form-group {
                    text-align: left;
                    margin-bottom: 30px;
                    label {
                        color: $primary;
                        font-size: 1.2rem;
                        font-weight: 600;
                        @include media-breakpoint-up(md) {
                            font-size: 1.5rem;
                        }
                    }
                    input {
                        color: $primary!important;
                    }
                }
                .form-control-submit {
                    margin-bottom: 30px;
                    span {
                        &:hover {
                            color: inherit;
                            background-color: inherit;
                            border: none;
                        }       
                    }
                }
            }
        }
        @include media-breakpoint-up(lg) {
            padding-inline: 0;
        }
    }
    &.page-wrapper--contact {
        @include media-breakpoint-up(lg) {
            padding-inline: 1.25rem;
        }
    }
    .page-footer {
        border-top: none;
        text-align: center;
        @include media-breakpoint-up(xl) {
            background-color: transparent;
        }
    }
    @include media-breakpoint-up(lg) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @include media-breakpoint-up(xl) {
        #left-column {
            margin-top: 60px!important;
            background-color: $tertiary;
            .contact-rich {
                .infos-header {
                    padding-inline: 0;
                    background-color: transparent;
                    word-break: break-word;
                }
            }
        }
    }
}
.page-wrapper--password,
.page-wrapper--identity,
.page-wrapper--address {
    @include media-breakpoint-up(lg) {
        margin: 40px auto;
        max-width: 65%;
    }
}
.page-wrapper--discount,
.page-wrapper--order-slip,
.page-wrapper--order-detail {
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}
@include media-breakpoint-up(lg) {
    .page-authentication {
        #content-wrapper {
            &.col-12 {
                .page-wrapper--authentication {
                    margin: 40px auto;
                    max-width: 65%;
                }
            }
            &.col-lg-8 {
                max-width: 66.66667%;
                .page-wrapper--authentication {
                    max-width: 100%;
                }
            }
        }
    }
}