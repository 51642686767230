.block_newsletter {
    #block-newsletter-label {
        margin-bottom: 19px;
        @include media-breakpoint-up(xl) {
            text-shadow: #3b3b3b 2px 2px 15px;
            margin-bottom: 35px;
        }
    }
    .conditions {
        font-weight: 500;
        font-size: 1rem;
        @include media-breakpoint-up(md){
          font-size: (20 / 16) + rem;
        }
        @include media-breakpoint-up(xl) {
            font-weight: 600;
            max-width: 80%;
            font-size: (24 / 16) + rem;
            line-height: initial;
        }
    }
    .needs-validation {
        .email-input {
            .form-control {
                border-radius: 42.5px;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-inline: 20px;
                height: 100%;
                position: relative;
                z-index: 10;
                &::placeholder {
                    color: #989898;
                    font-weight: 700;
                }
            }
            .input-img {
                position: absolute;
                right: -5px;
                top: -6px;
                width: 55px;
                z-index: 20;
            }
            @include media-breakpoint-up(xl) {
                max-width: 60%;

                input{
                  height: 4.5rem !important;
                }
                .input-img {
                  top: initial;
                  right: -50px;
                  width: 4.5rem;
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        padding-inline: 60px;
    }
    @include media-breakpoint-up(xxl) {
        padding-inline: 5rem !important;
    }
}

section#newsletters{
  @include media-breakpoint-up(xl){
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-up(xxl){
    max-width: 1744px;
    padding-left: initial;
    padding-right: initial;
  }
}