// Here you can add some css property to bootstrap classes
$colors: 
    "primary" $primary,
    "secondary" $secondary,
    "tertiary" $tertiary;

* {
    font-family: 'Moderat';
}

/*** BUTTONS ***/
.btn {
    @include custom-font-size();
    @extend .font-weight-bold;
    color: #fff;
    background-color: $primary;
    border: 2px solid $primary;
    border-radius: 42.5px;
    padding: 10px 30px;
    width: fit-content;
    @include media-breakpoint-up(xl) {
        padding: 20px 50px;
    }
    @include media-breakpoint-up(xxl) {
        padding: 25px 55px;
    }
    #lgcookieslaw_modal &{
      font-size: initial !important;
      padding: 10px 20px;
    }
    &:not(.slick-next, .slick-prev):hover {
        color: $primary;
        background-color: $white;
        border: 2px solid $primary;
    }
    i:hover, p:hover, div:hover, span:hover, svg:hover, a:hover{
        background-color: initial;
        color: inherit;
        border: none;
    }
    &:hover{
      color: #fff;
      background: darken($primary, 10%)
    }
}
@each $name, $color in $colors {  
    .btn-#{$name} { 
        background-color: #{$color};
        border: 2px solid #{$color};
        &:hover, 
        &:not(.facet__header):hover i, 
        &:not(.facet__header):hover p, 
        &:hover div, 
        &:hover span, 
        &:not(.facet__header):hover a{
          transition: all 0s;
            color: #{$color};
            background-color: $white;
        }
        &:hover{
          border: 2px solid #{$color};
        }
        &--light {
            color: #{$color};
            background-color: $white;
            border: 2px solid #{$color};
            &:hover {
                color: $white;
                background-color: #{$color};
                border: 2px solid #{$color};
            }
        }
    }
}
/****************/

/*** TITLES ***/

h1, h2, h3, h4, h5, h6 {
    text-align: center;
}
h1 {
    font-size: 1.5rem;
    font-weight: 700;
    @include media-breakpoint-up(sm) {
        font-size: 2rem;
    }
    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: 4.5rem;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 5.6rem;
    }
}
h2, .h2 {
    font-weight: 900;
    font-size: 26px;
    text-align: center;
    @include media-breakpoint-up(md) {
        font-size: (40 / 16) + rem;;
    }
    @include media-breakpoint-up(xl) {
        font-size: 3rem;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 4rem;
    }
}
h3, .h3{
  font-weight: 900;
  font-size: (20 / 16) + rem;
  line-height: 25px;
  @include media-breakpoint-up(lg) {
    font-size: (30 / 16) + rem;
    line-height: 35px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
    line-height: 65px;
  }
}

/**************/

/*** BACKGROUND ***/
.bg-xl-tertiary {
    @include media-breakpoint-up(xl) {
        background-color: $tertiary;
    }
}
/*********************/

/*** TEXT SHADOW ***/
.text-shadow {
    text-shadow: #3b3b3b 2px 2px 15px;
}

/*** MODAL ***/
.modal-xxl{
  max-width: 960px;
  @include media-breakpoint-between(xsm, sm){
    margin-left: 30px;
    margin-right: 30px;
  }
  @include media-breakpoint-up(xl){
    max-width: 1140px;
  }
  @include media-breakpoint-up(xxl){
    max-width: 1744px;
  }
}

/*** FORM ***/
.custom-file, input.custom-file-input, label.custom-file-label, input.form-control, select.custom-select{
  height: 50px;

  @include media-breakpoint-up(lg){
    height: 70px;
  }
}
input.form-control, select.custom-select{
  border-radius: 100px;
  font-weight: 700;
  border: 2px solid $primary;
  @include media-breakpoint-up(lg){
    border: 3px solid $primary;
  }

  &::placeholder {
    color: $gray-400;
  }
}
label.custom-file-label{
  border: 2px solid $primary;
  border-radius: 100px;
  display: flex;
  align-items: center;
  @include media-breakpoint-up(lg){
    border: 3px solid $primary;
  }

  &:after{
    background-color: $primary;
    color: $white;
    height: initial;
    display: flex;
    align-items: center;
    border-radius: 0 80px 80px 0;
  }
}
select.custom-select{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11'%3E%3Cpath fill='#0E4D76' d='M7 11L0.0717954 0.5L13.9282 0.500001L7 11Z'/%3E%3C/svg%3E");
  background-size: initial;

  .filter-buttons--sort_by &{
    @include media-breakpoint-down(sm){
      font-size: $custom-small-font-size-base;
    }
    line-height: 1.5rem;
  }
}