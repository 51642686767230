img[data-lazy] {
  display: none;
}
.text-underline{
  text-decoration: underline;
}
.cursor-pointer{
  cursor: pointer;
}

.visible--desktop{
  @include media-breakpoint-mobile(){
    display: none;
  }
}
.visible--mobile{
  // z-index: 2000;
  @include media-breakpoint-desktop(){
    display: none;
  }
}
.d--none{
  display: none;
}
.d--block{
  display: block;
}

.full-bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}
.d--flex-between{
  display: flex;
  justify-content: space-between;
}
.mb--0{
  margin-bottom: 0;
}
.text--center{
  text-align: center;
}
.text--right{
  text-align: right;

}
.u-a-i-c{
  align-items: center;
}
.u-m-l-2{
  margin-left: $spacer/2;
}
.u-link-body{
  color: $body-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span.visible--desktop {
    font-size: .875rem;
  }
  img {
    height: 34px;
    @include media-breakpoint-up(xl){
      height: 45px;
    }
    @include media-breakpoint-up(xxl){
      height: 60px;
    }
  }
}
.u-bor-bot{
  border-bottom:1px solid $border-color;
}
