#cms{
  .page-wrapper--cms{
    border: none;
    color: $primary;
    margin-bottom: 40px;
    @include media-breakpoint-up(lg){
      margin-bottom: 78px;
    }
  
    h1, h2, h3{
      text-align: start;
    }
    h1{
      font-weight: 900;
      font-size: (26 / 16) + rem;
      @include media-breakpoint-up(lg){
        font-weight: 800;
        font-size: (40 / 16) + rem;
      }
      @include media-breakpoint-up(xxl){
        font-size: (64 / 16) + rem;
      }
    }
    h2{
      font-size: (20 / 16) + rem;
      font-weight: 700;
      @include media-breakpoint-up(lg){
        font-size: (30 / 16) + rem;
        margin-bottom: 20px;
        margin-top: 45px;
      }
      @include media-breakpoint-up(xxl){
        font-size: (48 / 16) + rem;
      }
    }
    h3{
      font-size: 1rem;
      font-weight: 700;

      @include media-breakpoint-up(lg){
        font-size: (24 / 16) + rem;
        margin-top: 45px;
      }
      @include media-breakpoint-up(xxl){
        font-size: (28 / 16) + rem;
      }
    }
    a:hover:not(.btn){
      color: $secondary;
    }
    ul{
      padding-left: 20px;
      @include media-breakpoint-up(lg){
        padding-left: 40px;
      }
  
    }
    ol li::marker{
      color: $secondary
    }
    img{
      max-width: 100%;
      height: auto;
    }
    .page-header--cms{
      background-color: initial;
      border: none;
      display: none;
    }
    table{
      width: 100%;
      overflow-x: auto;
    }
  }
}
.cms-id-74{
  .page-content{
    padding-left: 0;
    padding-right: 0;
  }
  #footer{
    padding-top: 0;
    .block-reassurances{
      @include media-breakpoint-up(lg){
        padding-top: 3.5rem !important;
      }
      background-color: $tertiary !important;
    }
  }
}