.page-search {
    #category-presentation {
        display: none;
    }
    #js-product-list-header {
        @extend .h2;
        margin-top: 20px;
        margin-bottom: 30px;
        color: $primary;
    }
    #js-product-list-top {
        select {
            line-height: 1.5rem;
            height: 100%;
            &:hover,
            &:focus {
                color: $light;
                option {
                    color: $primary;
                }
            }
            @include media-breakpoint-up(lg) {
                background-color: transparent;
                color: $primary !important;
                &:hover,
                &:focus {
                    color: $primary;
                    option {
                        color: $primary
                    }
                }
            }
        }
    }
    #js-product-list {
        .page-not-found {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $primary;
            padding-top: 0;
            text-align: center;
            padding-top: 0;
            h4 {
                font-size: 1.2rem;
                font-weight: 400;
                @include media-breakpoint-up(xl) {
                    font-size: 1.6rem;
                }
            }
            p {
                font-size: 1rem;
                font-weight: 300;
                margin-bottom: 20px;
                @include media-breakpoint-up(xl) {
                    font-size: 1.4rem;
                    margin-top: 25px;
                }
            }
            #search_widget {
                .search-btn {
                    svg {
                        path {
                            fill: #c6c4c5
                        }
                    }
                    position: absolute;
                    right: 15px;
                    top: 10px;
                    @include media-breakpoint-up(lg) {
                        top: 24px;
                        right: 10px;
                    }
                    @include media-breakpoint-up(xl) {
                        right: 35px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    form {
                        width: 100%;
                        .form-control {
                            padding: 6px 45px 8px 20px;
                        }
                        .search-btn {
                            right: 15px;
                            top: 24px;
                        }
                    }
                }
                @include media-breakpoint-up(xxl) {
                    form {
                        .form-control {
                            min-width: 100%
                        }
                        .search-btn {
                            top: 34px;
                        }
                    }
                }
            }
        }
    }
    hr.separation {
        display: none;
    }
}