/* typo base size */
$custom-small-font-size-base:(13 / 16) + rem;
$custom-medium-font-size-base: 1rem;
$custom-large-font-size-base: (24 / 16) + rem;
@mixin custom-font-size(){
  font-size: $custom-small-font-size-base;

  @include media-breakpoint-up(md){
    font-size: $custom-medium-font-size-base;
  }
  @include media-breakpoint-up(xxl){
    font-size: $custom-large-font-size-base
  }
}

@mixin media-breakpoint-desktop() {

  @include media-breakpoint-up(lg) {
    @content;
  }
}
@mixin media-breakpoint-mobile() {

  @include media-breakpoint-down(md) {
    @content;
  }
}

@mixin toRem($property, $value) {
  #{$property}: ($value / 16) + rem;
}