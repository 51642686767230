#module-wkproductsubscription-subscriptiondetails {
    .wk-product-subscription {
        h1 {
            @include media-breakpoint-up(md) {
                font-size: 40px;
            }
        }
        .shipping-block {
            padding: 20px;
            .shipping-icon {
                display: none;
            }
        }
        .h4 {
            font-size: 18px;
            @include media-breakpoint-up(md) {    
                font-size: 20px;
                font-weight: 600;
            }
        }
        .page-header {
            .row {
                flex-wrap: nowrap;
                .col-md-8,
                .col-md-4 {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    max-width: none;
                    .btn {
                        display: flex;
                        align-items: center;
                        i {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .page-content {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-direction: column;
                gap: 25px;
            }
            .address-item {
                .address {
                    padding: 10px;
                }
            }
            #updateSubscriptionDetails {
                @include media-breakpoint-up(md) {
                    width: 100%;
                }
                .quantity-block {
                    justify-content: center;
                }
                .card-block  {
                    .row {
                        .col-md-3 {
                            text-align: center;
                        }
                    }
                    h3 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.product-name {
                            @include media-breakpoint-up(md) {
                                font-size: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                #cancelSubscription {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    padding: 10px;
                    margin-inline: auto;
                    i {
                        margin-right: 5px;
                    }
                }
            }
            .card-block {
                @include media-breakpoint-up(md) {
                    height: 100%;
                    h3 {
                        display: flex;
                        align-items: center;
                        font-size: 22px;
                        i {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}