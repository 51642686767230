//
// Color system
//
$white:     #fff;
$primary:   #0E4D76;
$secondary: #EC893F;
$tertiary:  #F5F5F5;
$black:     #000;
$red:       #d62836;
$green:     #00C859;
$purple:    #772275;
$gray:      #737373;
$gray-200:  #e9ecef;
$gray-300:  #f1f1f1;
$gray-400:  #ced4da;
$body-color:  $primary;

// Options
$enable-rounded:            false;
$enable-shadows:            true;

//
// Links
$link-hover-color: $primary;
//
// Style anchor elements.

// Layout
$wrapper-bg:                    #fff;


$link-hover-decoration:     none;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:     Moderat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family:        Moderat, $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`


$h1-font-size:                3.4375rem;
$h2-font-size:                3.125rem;
$h3-font-size:                2.8125rem;
$h4-font-size:                2.5rem;
$h5-font-size:                2.1875rem;
$h6-font-size:                1.875rem;

$display1-size: 3.4375rem;
$display1-weight: 700;
$display2-size: 3.125rem;
$display2-weight: 500;
$display3-size: 2.8125rem;
$display3-weight: 500;
$display4-size: 2.5rem;
$display4-weight: 500;

$small-font-size:             80%;


// Components
//
// Define common padding and border radius sizes and more.

$box-shadow:                  2px 2px 8px 0 rgba($black,.2);

// Navs

$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;
// Pagination

$pagination-border-color:           $gray-400;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;

// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;

//breadcrumb
$breadcrumb-bg: $wrapper-bg;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        xsm: 428px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1800px,
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1744px
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;
$grid-row-columns:            6;

$custom-file-text: (
  en: "Browse",
  fr: "Choisir un fichier"
);
