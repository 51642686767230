.carousel a {
  display: block;
  &:not(:first-child) {
    display: none;
  }
}
.slider-caption{
  position: absolute;
  top:10%;
  left: 90px;
  margin-bottom: 0;
  color: #fff;
  max-width: 340px;
}

.slick-slide:not(.slick-current) .img-carousel.lazyload {
  display: none!important;
}
.slick-slide figure{
  position: absolute;
  top: 0;
}

.homeslider.swiper {
  @include media-breakpoint-up(lg){
    max-width: 65%;
    min-width: 65%;
    margin-left: 0;
  }
  @include media-breakpoint-up(xl){
    max-width: 70%;
    min-width: 70%;
  }
  .slide-img {
    @include media-breakpoint-up(md){
      min-height: 400px;
      height: 400px;
      max-height: 400px;
      background-position: center!important;
      background-size: cover!important;
      background-repeat: no-repeat!important;
    }
    @include media-breakpoint-up(xxl){
      min-height: 630px;
      height: 630px;
    }
  }
}