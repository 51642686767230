/* cart (panier) */
body#cart{
  color: $primary;

  .cart-grid{
    .cart-grid-body{
      a.label{
        line-height: initial;
      }
      .card-header{
        @extend .h2;
        border: none;
        background-color: $tertiary;
      }
      .media-body{
        .product-line__title, .current-price{
          font-size: 1rem;
          font-weight: 800;
          color: $primary;
  
          @include media-breakpoint-up(lg){
            font-size: (24 / 16) + rem;
          }
        }
        .regular-price{
          font-size: (13 / 16) + rem;
          font-weight: 400;
          text-decoration: line-through;
  
          @include media-breakpoint-up(lg){
            font-size: (20 / 16) + rem;
          } 
        }
      }
      .product-line-actions{
        .dw-qty-cart{
          position: relative;
          padding: 0;
          margin-right: 41px;
          @include media-breakpoint-up(lg) {
            margin-right: 55px;
          }
          .bootstrap-touchspin{
            border: none;
            input.js-cart-line-product-quantity{
              background-color: transparent;
              max-width: initial;
              width: 100% !important;
              height: 58px;
              border-radius: 50px 0 0 50px!important;
              border-right: none!important;
              border: 3px solid $primary;
              
              color: $primary;
              font-weight: 700;
              font-size: (18 / 16) + rem;
              
              @include media-breakpoint-up(xxl){
                width: 164px!important;
                height: 80px;
                font-size: (24 / 16) + rem;
              }
            }
            .input-group-btn{
              position: absolute;
              right: -41px;
              z-index: 10;
              margin: 0!important;
              height: 50%;
              &.input-group-append{
                top: 0;
                .dw-quantity{
                  border-bottom: 1px solid $primary;
                  border-radius: 0 50px 0 0;
        
                  svg{
                    transform: translate(-60%, 0%);
                  }
                }
              }
              &.input-group-prepend{
                bottom: 0;
                .dw-quantity{
                  border-top: 1px solid $primary;
                  border-radius: 0 0 50px 0;
        
                  svg{
                    transform: translate(-60%, -100%);
                  }
                }
              }
              .dw-quantity{
                cursor: pointer;
                height: 100%;
                width: 41px;
                border: 3px solid $primary;
                background-color: transparent;
        
                svg{
                  position: absolute;
                  top: 50%;
                  left: 50%;
                }
              }
            }
          }
        }
      }
    }
    .cart-grid-right{
      .cart-summary{
        @include media-breakpoint-down(md){
          .card-footer{
            padding: 0;
            z-index: 11;
          }
        }
      }
    }
  }
  .cart__card-body.is--loading .cart__card-loader{
    z-index: 50;
  }
}

/* checkout section */
body#checkout{
  color: $primary;
  .checkout-process{
    .input-group-btn {
      .btn {
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        &.hide {
            background-image: url(../img/design/hide.png);
        }
        &.show {
            background-image: url(../img/design/view.png);
        }
        &:hover {
            background-color: white;
        }
      }
    }
  }
  #contact-link{
    @extend .btn;
    @extend .btn-primary;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(xl){
      padding: 10px 30px;
    }

    svg{
      margin-left: $spacer/2;
      transform: rotate(-90deg);

      path{
        stroke: $white;
      }
    }
    a{
      color: $white;
    }
    &:hover{
      svg{
        path{
          stroke: $primary;
        }
      }
    }
  }
  .card-header{
    button.step-edit{
      background-color: rgba($color: $primary, $alpha: .7);
      border: none;
      color: $white;

      &:hover{
        background-color: $primary;
      }

      @include media-breakpoint-down(md){
        padding: 10px;
      }
    }
  }
}