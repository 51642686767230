#custom-text,
#mobile-custom-text {
    @include media-breakpoint-up(lg){
        position: absolute;
        top: 0;
        right: 15px;
        height: 100%;
        max-height: 100%;
        max-width: calc(35% - 11px);
        min-width: calc(35% - 11px);
    }
    @include media-breakpoint-up(xl){
        max-width: calc(30% - 9px);
        min-width: calc(30% - 9px);
    }
    @include media-breakpoint-up(xxl){
        right: 0;
        max-width: 30%;
        min-width: 30%;
    }
    .row {
        margin-inline: 0;
    }
    .insurance {
        min-height: 100%;    
        padding-inline: 0;
        @include media-breakpoint-up(lg){
            min-height: 200px;
            max-height: 200px;
            background-position: bottom!important;
            background-size: cover!important;
            background-repeat: no-repeat!important;
        }
        @include media-breakpoint-up(xxl){
            min-height: 315px;
            max-height: 315px;
        }   
        img {
            width: 100%;
        }
        .caption {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 15px;
            .tag {
                height: 20%;
                max-height: 20%;
                margin-bottom: 0;
            }
            .text-wrapper {
                height: 80%;
                max-height: 80%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .text {
                    font-size: 1rem;
                    font-weight: 800;
                    line-height: 20px;
                    margin-bottom: 0;
                }
                .btn {
                    padding-inline: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
            @media screen and (min-width: 420px) {
                .text-wrapper {
                    .text {
                        font-size: (20 / 16) + rem;
                        line-height: 26px;
                    }
                    .btn {
                        padding-inline: 20px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
            @include media-breakpoint-up(sm) {
                padding: 20px;
                .text-wrapper {
                    .text {
                        line-height: 34px;
                    }
                    .btn {
                        padding-inline: 32px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                }
            }
            @include media-breakpoint-up(md) {
                .text-wrapper {
                    .text {
                        font-size: (30 / 16) + rem;
                        line-height: (38 / 16) + rem;
                    }
                    .btn {
                        padding-inline: 35px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                .text-wrapper {
                    .text {
                        font-size: 1rem;
                        line-height: 1.2;
                        padding-bottom: 15px;
                    }
                    .btn {
                        padding: 8px 25px;
                        font-size: 0.8rem;
                    }
                }
            }
            @include media-breakpoint-up(xxl) {
                .text-wrapper {
                    .text {
                        font-size: 1.5rem;
                    }
                    .btn {
                        font-size: 1.2rem;
                        padding: 15px 50px;
                    }
                }
            }
        }
        &.refund {
            .caption {
                color: $white;
            }
        }
        &.informations {
            hr {
                margin-top: 0;
                margin-bottom: 0;
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
    }
}