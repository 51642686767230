#product {
  /* global */
  #main{
    *:not(button.add-to-cart), p{
      color: $primary;
    }
    h1{
      font-size: (20 / 16) + rem;
      font-weight: 800;
      text-align: start;
      
      @include media-breakpoint-up(lg){
        font-size: (30 / 16) + rem;
      }
      @include media-breakpoint-up(xxl){
        font-size: (45 / 16) + rem;
      }
    }
  }

  /* right top content */
  .content-product-topRight{
    margin-bottom: 50px;

    @include media-breakpoint-up(xxl){
      padding-left: 95px;
      padding-right: 21px;
      margin-bottom: 125px;
    }
  }
  
  .covered_social_security{
    font-weight: 500;
    
    @include media-breakpoint-up(xxl){
      font-size: (20 / 16) + rem;
    }
  }

  .product-prices{
    font-weight: 800;
    .current-price-display{
      font-size: (24 / 16) + rem;
    
      @include media-breakpoint-up(lg){
        font-size: (35 / 16) + rem;
      }
      @include media-breakpoint-up(xxl){
        font-size: (55 / 16) + rem;
      }
    }
    .regular-price{
      text-decoration: line-through;
      font-size: 1rem;
    
      @include media-breakpoint-up(lg){
        font-size: (20 / 16) + rem;
      }
      @include media-breakpoint-up(xxl){
        font-size: (30 / 16) + rem;
      }
    }
  }
  .product-information{
    .read-more{
      text-align: center;
      display: block;
      font-size: 1rem;
      font-weight: 500;
    
      @include media-breakpoint-up(lg){
        font-size: (18 / 16) + rem;
      }
      @include media-breakpoint-up(xxl){
        font-size: (24 / 16) + rem;
      }
      &:hover{
        color: $secondary!important;
  
        .line--small{
          background-color: $secondary!important;
        }
      }
    }
  }
  .product-variants{
    @include media-breakpoint-up(xxl){
      width: fit-content;
      flex: none;
      max-width: none;
    }
    .label-color--wrapper{
      @include media-breakpoint-up(xxl){
        flex-wrap: wrap;
        display: flex;
        align-items: center;
      }
      .label-color:not(:last-child){
        margin-right: 16px;
      }

    }
    label.label-color{
      border: 2px solid $primary;
      padding: 4px;
      border-radius: 50%;
      span.color{
        width: 23px;
        display: block;
        height: 23px;
        border: 2px solid $primary;
        border-radius: 50%;
      }
    }
    &--wrapper{
      display: flex;
      flex-direction: column-reverse;
      justify-content: end;

      @include media-breakpoint-up(xl){
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: initial;
        margin-right: -15px;
        margin-left: -15px;
      }
      
      .product-variants-item{
        margin-bottom: 0;
      }
    }
  }
  .product-add-to-cart{
    .product-quantity{
      &--wrapper{
        display: flex;
        flex-direction: column;
        justify-content: end;
        @include media-breakpoint-up(xxl){
          width: 194px;
          flex: none;
          max-width: none;
        }
      }
      .qty{
        @include media-breakpoint-up(xxl){
          width: 164px;
        }
      }
      .qty, .size{
        position: relative;
        padding: 0;

        input, select {
          width: 100% !important;
          height: 58px;
          text-align: center;
          border: 3px solid $primary;
          border-radius: 100px;
          color: $primary;
          font-weight: 700;

          @include media-breakpoint-up(xxl){
            width: 164px!important;
            height: 80px;
          }
        }
        input[type=number]{
          padding-right: 38px;
        }
      }
      .add {
        .btn.disabled {
          &:hover {
            background-color: #0e4d76;
            border-color: #0e4d76;
            color: white;
          }
        }
      }
    }
  }
  .product-additional-info{
    .product-delivery-item{
      border-top:  1px solid rgba($color: $primary, $alpha: 0.25);
      border-bottom:  1px solid rgba($color: $primary, $alpha: 0.25);
      padding: 9px 0;
      max-width: 458px;
      margin: 32px auto;
      text-align: center;
      font-weight: 600;
  
      @include media-breakpoint-up(xl){
        font-weight: 700;
        margin: 21px auto 27px;
      }
    }
    .product-more-infos{
      margin: 0 -15px;
      @include media-breakpoint-up(xsm){
        margin: 0 -30px;
      }
      @include media-breakpoint-up(sm){
        margin: initial;
      }
      
      &-items{
        justify-content: space-evenly;
        font-weight: 700;

        img{
          width: 28px;

          @include media-breakpoint-up(lg){
            width: initial;
          }
        }
      }
    }
  }
  /* full width content */
  .product-main-desc{
    padding: 15px 30px 30px;

    &-title{
      font-size: (18 / 16) + rem;
      font-weight: 900;
      text-align: start;
      margin-bottom: 17px;
    }

    @include media-breakpoint-up(lg){
      padding: 45px;

      &-title{
        font-size: (24 / 16) + rem;
        font-weight: 700;
        margin-bottom: 35px;
        
        @include media-breakpoint-up(xxl){
          font-size: (28 / 16) + rem;
        }
      }
    }
  }
  .product-accessories, .featured-products{
    @include media-breakpoint-down(xxl){
      max-width: 1500px;
      overflow: hidden;
      margin: 0 auto;
    }
    .product-miniature.js-product-miniature{
      flex: 0 0 100%;
      max-width: 100%;
    }
    @include media-breakpoint-down(xsm){
      .swiper-slide .product-miniature.js-product-miniature{
        padding-right: 0;
      }
    }
    .swiper-button{
      &-prev,
      &-next{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 80;
        cursor: pointer;
        svg {
          max-width: 50px;
          max-height: 50px;
          &>path:first-child:hover{
            fill: $secondary;
          }
        }
      }
      &-prev{
        left: 0px;
      }
      &-next{
        right: 0px;
      }
      &-disabled{
        path:not(:last-child){
          fill: $gray-400;
        }
      }
    }
  }

  /* images slider + flags */
  .page-content--product{
    position: relative;
    .images-container{
      padding: 0 15px;
      @include media-breakpoint-up(xsm){
        padding: 0 30px;
      }
      @include media-breakpoint-up(md){
        padding: 0;
      }
      .product-thumbs{
        overflow: hidden;
        .slick-list {
          margin-right: -27px;
          @include media-breakpoint-up(xxl){
            margin-right: -53px;
          }
        }
        .slick-slide{
          margin-right: 27px;
          @include media-breakpoint-up(xxl){
            margin-right: 53px;
          }
        } 
      }
    }
  }

  .subscription-block {
    .btn {
      &:hover {
        color: $secondary!important;
      }
    }
  }
  // SUBCRIPTION BLOCK
  .wk-subscription-block {
    .wksubscribe {
      margin-top: 0;
      input[type="radio"] {
        accent-color: $primary;
      }
      label {
        font-size: 1.10rem;
        font-weight: 600;
      }
      .subscribe {
        .wksubscription-options {
          margin-bottom: 0;
          label {
            font-size: 1rem;
            font-weight: 500;
          }
          #wkFirstDeliveryDate {
            border-radius: 0;
            border-width: 1px;
            font-weight: 400;
            border-color: #ced4da;
          }
          .reassurances {
            background: #eef4f1;
            > div {
              display: flex;
              align-items: stretch;
              justify-content: space-between;
              gap: 20px;
              margin-bottom: 15px;
              > div {
                width: calc(50% - 10px);
                max-width: calc(50% - 10px);
                p {
                  margin-bottom: 8px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
            * {
              color: #455f64!important;
              font-size: 1rem;
              font-weight: 400;
              b {
                font-weight: 800;
              }
            }
          }
        }
      }
    }
  }
  // --- END OF SUBSCRIPTION BLOCK
}
  .product-additional-info {
    .product-more-infos {
      .product-more-infos-items {
        .product-more-infos-item {
          font-weight: 700;
          img {
            width: 28px;
            max-width: 28px;
            @include media-breakpoint-up(xl) {
              width: 60px;
              max-width: 60px;
            }
          }
        }
      }
    }
  }
  .products.js-swiper-alsoLike {
    .swiper-button-prev,
    .swiper-button-next {
      width: fit-content;
      position: absolute;
      top: 43%;
      z-index: 10;
      @include media-breakpoint-up(lg) {
        top: 42%;
      }
      @include media-breakpoint-up(xl) {
        top: 46%;
      }
      @include media-breakpoint-up(xxl) {
        top: 42%;
      }
      >svg {
        width: 60px;
        @include media-breakpoint-up(xl) {
          width: 70px;
        }
        @include media-breakpoint-up(xxl) {
          width: 100%;
        }
      }
    }
    .swiper-button-prev {
      left: 0px;
      @include media-breakpoint-up(xxl) {
        left: 40px;
      }
    }
    .swiper-button-next {
      right: 0px;
      @include media-breakpoint-up(xxl) {
        right: 40px;
      }
    }
  }

.product-flags{
  position: absolute;
  top: 0;
  z-index: 1;

  #category &{
    right: 15px;
  }
  #product &{
    right: 15px;
    @include media-breakpoint-up(xsm){
      right: 30px;
    }
    @include media-breakpoint-up(md){
      right: 0;
    }
  }

  .product-flag{
    background-color: $secondary;
    color: $white !important;
    padding: 11.5px 10px;
    font-weight: 700;
    text-align: center;

    @include media-breakpoint-up(xl){
      // padding: 21px 36px;
      padding: 15px 20px;
    }
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }
}