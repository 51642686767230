.page-home {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        .custom-text-2-wrapper {
            order: 1;
        }
        .our-essentials {
            order: 3;
        }
        .our-favorites {
            order: 4;
        }
        .custom-text-wrapper {
            order: 5;
        }
        .block-reassurances {
            order: 6;
            margin-top: 0;
            padding-top: 70px;
        }
        #newsletters {
            order: 7;
        }
        #redirections-block {
            order: 8;
        }
    }
    @include media-breakpoint-down(md){
        .desktop-slider-cpam {
            order: 2;
        }
    }
    @include media-breakpoint-up(lg){
        .desktop-slider-cpam {
            position: relative;
        }
    }
}