.page-my-account {
  .page-header--my-account {
      h1 {
          margin-top: 20px;
          margin-bottom: 30px;
          color: $primary;
          @include media-breakpoint-up(xl) {
              font-size: 3.5rem;
          }
      }
  }
}
.account-links{
  @extend .align-items-stretch;
  > a{
    margin-bottom: $spacer;
  }
  .link-item{
    @extend .card;
    border: none;
    background-color: $tertiary;
    padding: $spacer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    i{
      margin: 0 auto $spacer;
      font-size: $font-size-base*3;

    }
  }
}

.address{
  @extend .card;
  height: 100%;
}
.address-header{
  @extend .card-header;
  background-color: transparent;
  color: $primary;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  border-bottom: none;
  background-color: $tertiary;
  @include media-breakpoint-up(lg) {
    font-size: (24 / 16) + rem;
  }
  @include media-breakpoint-up(xxl) {
    font-size: (30 / 16) + rem;
  }
}
.address-body{
  @extend .card-body;
  color: $primary;
}
.address-footer{
  @extend .card-footer;
  display: flex;
  justify-content: space-between;
  border-top: none;
  background-color: $tertiary;
}

.addresses-footer {
  text-align: center;
  .btn {
    display: flex;
    align-items: center;
    margin: auto;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.form--100{
  min-width: 100%;
  width: 100%;
}

.page-wrapper--identity,
.page-wrapper--address,
.page-wrapper--addresses,
.page-wrapper--module-psgdpr-gdpr,
.page-wrapper--discount,
.page-wrapper--order-slip,
.page-wrapper--order-detail {
  border: none;
  .page-header {
    border: none;
  }
  .page-footer {
    border: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    a{
      &:last-child {
        margin-top: 10px;
      }
    }
  }
}

.page-wrapper--module-psgdpr-gdpr {
/*   .page-content {
    padding: 0;
    >.container {
      padding-inline: 0;
    }
  } */
  .page_content {
    .psgdprinfo17 {
      color: $primary;
      text-align: center;
      h2 {
        margin-bottom: 15px;
        @include media-breakpoint-up(md) {
          font-size: 1.7rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 2.2rem;
        }
      }
      .btn.psgdprgetdatabtn17 {
        float: none;
        &:last-child {
          margin-top: 10px;
          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
        }
        @include media-breakpoint-up(md) {
          font-size: 0.8rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 1rem;
          padding: 15px 30px;
        }
      }
      @include media-breakpoint-up(xl) {
        max-width: 1000px;
        margin-inline: auto;
        margin-bottom: 30px;
      }
    }
  }
}