.our-essentials-block {
    padding-left: 15px;
    padding-right: 0;
    @include media-breakpoint-up(xsm) {
        padding-left: 30px;
    }
    @include media-breakpoint-up(md){
      padding-right: 30px;
    }
    @include media-breakpoint-up(xxl){
      padding-right: 0;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 4.25rem!important;
    }
    @include media-breakpoint-up(xxl) {
        padding-top: 8.5rem!important;
        padding-bottom: 8.5rem!important;
        padding-left: 0;
    }
}