.custom-text-2-wrapper,
.contact-banner-wrapper {
    #custom-text-2,
    .contact-banner {
        p {
            margin-bottom: 0;
            span {
                &:first-child {
                    font-size: 0.9375rem;
                    line-height: 20px;
                }
            }
            a {
                color: white;
            }
            @include media-breakpoint-up(lg) {
                font-size: 1rem;
                text-transform: lowercase;
                span {
                    padding-left: 5px;
                    &:first-child {
                        font-size: 1.25rem;
                        padding-right: 10px;
                        padding-left: 0px;
                    }
                }
            }
            @include media-breakpoint-up(xxl) {
                font-size: 1.3125rem;
                span {
                    &:first-child {
                        font-size: 1.75rem;
                    }
                }
            }
        }   
    }
}