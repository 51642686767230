.block-reassurances {
    @include media-breakpoint-up(lg){
      padding-top: 100px;
      margin-top: -110px;
    }
    .reassurances {
        .reassurance {            
            @include media-breakpoint-up(md) {
                font-size: 1rem;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 1.5rem;
            }
            img {
                margin-bottom: 10px;
                @include media-breakpoint-up(xl) {
                    width: 70px;
                }
                @include media-breakpoint-up(xxl) {
                    width: 115px;
                }
            }
            .title {
                font-weight: 700;
                margin-bottom: 10px;
                min-height: 35px;
                @include media-breakpoint-up(md) {
                    min-height: 50px;
                }
                @include media-breakpoint-only(lg) {
                    min-height: fit-content;
                }
                @include media-breakpoint-up(xxl) {
                    min-height: 70px;
                }
            }
            .description {
                font-weight: 300;
                margin-bottom: 0px;
            }
        }
    }
    &--category-product {
        margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 55px;
        margin-top: 50px;
        &--category-product {
            margin-top: 0;
            background-color: white!important;
        }
    }
}

body#product .blockreassurance_product{
  .block-reassurances{
    margin: 60px 0 50px;
    padding-top: initial;

    @include media-breakpoint-up(xl){
      margin: 140px 0;
    }
  }
}

body#cart,
body#checkout{
  .blockreassurance_product{
    margin-top: 0;
    .block-reassurances{
      padding-top: 0;
      margin-top: 0;
      .reassurances{
        flex-direction: column;
    
        .reassurance{
          display: flex;
          flex: 0 0 100%;
          max-width: 100%;
    
          img{
            width: initial;
            margin-right: 15px;
            margin-bottom: 0;
            max-width: 60px;
          }
    
          .block-reassurances-text{
            min-height: auto;
            text-align: start;
    
            &.title{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
} 