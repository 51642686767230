#module-dwhealthinsurancerefund-requests{
  @include media-breakpoint-up(xl){
    font-weight: 400;
    font-size: (20 / 16) + rem;
  }
  @include media-breakpoint-up(xxl){
    font-size: (24 / 16) + rem;
  }
  .dwhf-title{
    margin-right:-1.25rem; 
    margin-left:-1.25rem;
  }
  .form-footer{
    input[name=url] {
      display: none !important;
    }
  }
  .custom-control-label:before,
  .custom-control-label:after{
    top: 50%;
    transform: translateY(-50%);
  }
}