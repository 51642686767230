@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Moderat';
  src: url('../fonts/Moderat-Light.eot');
  src: url('../fonts/Moderat-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Moderat-Light.woff2') format('woff2'),
      url('../fonts/Moderat-Light.woff') format('woff'),
      url('../fonts/Moderat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/Moderat-Bold.eot');
  src: url('../fonts/Moderat-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Moderat-Bold.woff2') format('woff2'),
      url('../fonts/Moderat-Bold.woff') format('woff'),
      url('../fonts/Moderat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/Moderat-Black.eot');
  src: url('../fonts/Moderat-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Moderat-Black.woff2') format('woff2'),
      url('../fonts/Moderat-Black.woff') format('woff'),
      url('../fonts/Moderat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/Moderat-Thin.eot');
  src: url('../fonts/Moderat-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Moderat-Thin.woff2') format('woff2'),
      url('../fonts/Moderat-Thin.woff') format('woff'),
      url('../fonts/Moderat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/Moderat-Medium.eot');
  src: url('../fonts/Moderat-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Moderat-Medium.woff2') format('woff2'),
      url('../fonts/Moderat-Medium.woff') format('woff'),
      url('../fonts/Moderat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/Moderat-Regular.eot');
  src: url('../fonts/Moderat-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Moderat-Regular.woff2') format('woff2'),
      url('../fonts/Moderat-Regular.woff') format('woff'),
      url('../fonts/Moderat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-base*$line-height-base;  /* Preferred icon size */
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
//fix height
.material-icons + span{
  display: inline-block;
}
.small .material-icons,
.btn-sm .material-icons{
  font-size: $small-font-size;
}
.btn-lg .material-icons{
  font-size: $font-size-lg*$btn-line-height-lg;
}

.pagination .material-icons{
  font-size: $font-size-base*$pagination-line-height;
}
.material-icons.md-18
{ font-size: 18px; line-height: 1}
.material-icons.md-24
{ font-size: 24px; line-height: 1}
.material-icons.md-36
{ font-size: 36px; line-height: 1}
.material-icons.md-48
{ font-size: 48px; line-height: 1}