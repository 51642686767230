.page-pagenotfound {
    .page-wrapper {
        border: none;
        .page-header {
            border: none;
            margin-top: 20px;
            margin-bottom: 30px;
            padding: 0;
            background: none;
            h1 {
                text-align: center;
            }
        }
        .page-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $primary;
            padding-top: 0;
            text-align: center;
            padding-top: 0;
            h4{
                @include custom-font-size();
            }
            p {
                margin-bottom: 20px;
                @include media-breakpoint-up(xl) {
                    margin-top: 25px;
                }
            }
            #search_widget {
                .search-btn {
                    svg {
                        path {
                            fill: #c6c4c5
                        }
                    }
                    position: absolute;
                    right: 15px;
                    top: 10px;
                    @include media-breakpoint-up(lg) {
                        top: 24px;
                        right: 10px;
                    }
                    @include media-breakpoint-up(xl) {
                        right: 35px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    form {
                        width: 100%;
                        .form-control {
                            padding: 6px 45px 8px 20px;
                        }
                        .search-btn {
                            right: 15px;
                            top: 24px;
                        }
                    }
                }
                @include media-breakpoint-up(xxl) {
                    form {
                        .form-control {
                            min-width: 100%
                        }
                        .search-btn {
                            top: 34px;
                        }
                    }
                }
            }
        }
    }
}